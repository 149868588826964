import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FetchApiService } from "@@intelease/web/intelease/services";
import {
  FinalAbstractCompleteModel,
  SelectedAbstractDetailLoadedPayloadInterface,
} from "@@intelease/app-models/abstract-review";
import { Observable } from "rxjs";
import { RestClient } from "@@intelease/web/utils";
import {
  EditMultiProvisionValueService,
  EditSnippetService,
  FetchMultiProvisionValueService,
} from "@@intelease/web/intelease/services/models";
import { FullValMultiProvisionValueModel } from "@@intelease/web/common/models/multi-provision-value";
import { FullProvisionModel } from "@@intelease/web/common/models";
import { environment } from "../../../../../apps/ui/src/environments/environment";
import { EditedComment } from "@@intelease/web/ui/src/lib/itls-comment";
import {
  FinalAbstractViewCompleteModel,
  OApiReqFullDocOutlineViewModel,
  OApiRespDocPairDiffResultModel,
  OApiRespFullDocPairDiffViewModel,
  OApiRespMinimalBookmarkViewModel,
  OApiRespMultiFullCommentViewModel,
  OApiRespTableOfContentsViewModel,
  OApiSingleEntityNoDataResponseModel,
} from "@@intelease/api-models/adex-api-model-src";
import { map } from "rxjs/operators";
import { cloneDeep, keyBy } from "lodash";

@Injectable({
  providedIn: "root",
})
export class AbstractReviewService {
  private static readonly API_VERSION_1 = "/v1";
  private static readonly API_VERSION_2 = "/v2";
  private static readonly FINAL_DOC_SETS = "/finalDocSets";
  private static readonly PROVISIONS = "/provisions";
  private static readonly COMMENTS = "/comments";
  private static readonly BOOKMARKS = "/bookmarks";
  private static readonly NOTES = "/notes";

  constructor(
    private httpClient: HttpClient,
    private fetchApiService: FetchApiService,
    private readonly restClient: RestClient,
    private editSnippetService: EditSnippetService,
    private fetchMultiProvisionValueService: FetchMultiProvisionValueService,
    private editMultiProvisionValueService: EditMultiProvisionValueService
  ) {}

  private static getCommentsBaseUrl(
    abstractUid: string,
    provisionUid: string
  ): string {
    return `${AbstractReviewService.FINAL_DOC_SETS}/${abstractUid}${AbstractReviewService.PROVISIONS}/${provisionUid}${AbstractReviewService.COMMENTS}`;
  }

  private static getBookmarksBaseUrl(abstractUid: string): string {
    return `${AbstractReviewService.FINAL_DOC_SETS}/${abstractUid}${AbstractReviewService.BOOKMARKS}`;
  }

  private static getNoteBaseUrl(
    abstractUid: string,
    provisionUid: string
  ): string {
    return `${AbstractReviewService.FINAL_DOC_SETS}/${abstractUid}${AbstractReviewService.PROVISIONS}/${provisionUid}${AbstractReviewService.NOTES}`;
  }

  getAbstract(
    payload
  ): Observable<SelectedAbstractDetailLoadedPayloadInterface> {
    const { abstractUid } = payload;
    return this.restClient
      .sendGetRequest<FinalAbstractViewCompleteModel>(
        AbstractReviewService.API_VERSION_2,
        `${AbstractReviewService.FINAL_DOC_SETS}/${abstractUid}`,
        FinalAbstractViewCompleteModel,
        {},
        (res) => res.data
      )
      .pipe(
        map((res) => {
          //TODO: (@mohammadhaji) maybe we could find a better way to do these kind of transformations
          const abstractCp = cloneDeep(res);
          delete abstractCp.formStructure;
          delete abstractCp.provisions;

          return {
            abstractDetail: abstractCp,
            formStructure: res.formStructure
              ? {
                  ...res.formStructure,
                  categories: keyBy(res.formStructure.categories, "uid"),
                }
              : res.formStructure,
            provisions: keyBy(res.provisions, "provisionUid"),
          } as SelectedAbstractDetailLoadedPayloadInterface;
        })
      );
  }

  loadTOCByAbstractUid(
    abstractUid: string
  ): Observable<OApiReqFullDocOutlineViewModel> {
    return this.restClient.sendGetRequest<OApiReqFullDocOutlineViewModel>(
      AbstractReviewService.API_VERSION_1,
      `${AbstractReviewService.FINAL_DOC_SETS}/${abstractUid}/outline`,
      OApiReqFullDocOutlineViewModel,
      {
        params: {
          view: "full",
        },
      }
    );
  }

  pickSnippetProvisions(
    abstractUid: string,
    provisionUid: string,
    snippetUid: string
  ): Observable<FullValMultiProvisionValueModel> {
    return this.editSnippetService.pickSnippetProvisions(
      abstractUid,
      provisionUid,
      snippetUid
    );
  }

  getProvisionFullDetail(
    abstractUid: string,
    provisionUid: string
  ): Observable<FullValMultiProvisionValueModel> {
    return this.fetchMultiProvisionValueService.getMultiProvisionValueView(
      abstractUid,
      provisionUid,
      FullValMultiProvisionValueModel.view,
      FullValMultiProvisionValueModel
    );
  }

  updateProvisionStatus(payload: {
    abstractUid: string;
    provisionUid: string;
    status:
      | "NOT_COMPLETED"
      | "COMPLETED"
      | "REVIEWED"
      | "NEED_REVIEW"
      | "NEED_REVIEW_TOUCHED"
      | "DEFAULT"
      | "COMPLETED_BUT_NEED_REVIEW";
  }): Observable<FullProvisionModel> {
    const { abstractUid, provisionUid, status } = payload;
    return this.editMultiProvisionValueService.updateMultiProvisionStatus(
      abstractUid,
      provisionUid,
      status,
      "MINIMAL",
      FullProvisionModel
    );
  }

  saveProvisionComment(
    payload
  ): Observable<OApiSingleEntityNoDataResponseModel> {
    const { abstractUid, provisionUid, comment } = payload;
    const _payload = {
      data: {
        text: comment.text,
        mentions: comment.mentions,
        ...comment.elements,
      },
    };
    return this.restClient.sendPostRequest<OApiSingleEntityNoDataResponseModel>(
      AbstractReviewService.API_VERSION_1,
      AbstractReviewService.getCommentsBaseUrl(abstractUid, provisionUid),
      _payload,
      OApiSingleEntityNoDataResponseModel
    );
  }

  saveProvisionNote(payload) {
    const { abstractUid, provisionUid, note } = payload;
    const _payload = {
      data: {
        notes: note,
      },
      returnParams: {
        view: "none",
      },
      replace: false,
    };
    return this.restClient.sendPostRequestNoView(
      AbstractReviewService.API_VERSION_1,
      AbstractReviewService.getNoteBaseUrl(abstractUid, provisionUid),
      _payload
    );
  }

  updateProvisionNote(payload) {
    const { abstractUid, provisionUid, note } = payload;
    const _payload = {
      data: {
        notes: note,
      },
      returnParams: {
        view: "none",
      },
      replace: false,
    };
    return this.restClient.sendPutRequestNoView(
      AbstractReviewService.API_VERSION_1,
      AbstractReviewService.getNoteBaseUrl(abstractUid, provisionUid),
      _payload
    );
  }

  updateProvisionComment(
    abstractUid: string,
    provisionUid: string,
    editedComment: EditedComment
  ): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.restClient.sendPutRequest<OApiSingleEntityNoDataResponseModel>(
      AbstractReviewService.API_VERSION_1,
      AbstractReviewService.getCommentsBaseUrl(abstractUid, provisionUid) +
        "/" +
        editedComment.uid,
      {
        data: {
          text: editedComment.text,
          mentions: editedComment.mentions,
          ...editedComment.elements,
        },
      },
      OApiSingleEntityNoDataResponseModel
    );
  }

  deleteProvisionComment(
    abstractUid: string,
    provisionUid: string,
    commentUid: string
  ): Observable<OApiSingleEntityNoDataResponseModel> {
    return this.restClient.sendDeleteRequest<OApiSingleEntityNoDataResponseModel>(
      AbstractReviewService.API_VERSION_1,
      AbstractReviewService.getCommentsBaseUrl(abstractUid, provisionUid) +
        "/" +
        commentUid,
      OApiSingleEntityNoDataResponseModel
    );
  }

  getProvisionCommentsByProvisionUid(
    abstractUid: string,
    provisionUid: string
  ): Observable<OApiRespMultiFullCommentViewModel> {
    return this.restClient.sendGetRequest<OApiRespMultiFullCommentViewModel>(
      AbstractReviewService.API_VERSION_1,
      AbstractReviewService.getCommentsBaseUrl(abstractUid, provisionUid),
      OApiRespMultiFullCommentViewModel,
      {
        params: {
          view: "full",
        },
      }
    );
  }

  compareDocuments(payload): Observable<OApiRespDocPairDiffResultModel> {
    const _payload = {
      data: payload,
      returnParams: {
        view: "full",
      },
    };
    return this.restClient.sendPostRequest<OApiRespDocPairDiffResultModel>(
      AbstractReviewService.API_VERSION_1,
      "/docPairDiffs",
      _payload,
      OApiRespDocPairDiffResultModel
    );
  }

  getDocPairDiffsByDiffUid(
    docDiffUid: string
  ): Observable<OApiRespFullDocPairDiffViewModel> {
    return this.restClient.sendGetRequest<OApiRespFullDocPairDiffViewModel>(
      AbstractReviewService.API_VERSION_1,
      `/docPairDiffs/${docDiffUid}`,
      OApiRespFullDocPairDiffViewModel,
      {
        params: {
          view: "full",
        },
      }
    );
  }

  getDocPairDiffsPdfUrl(
    type: "source" | "target",
    abstractUid: string,
    diffUid: string,
    docUid: string
  ): string {
    if (type === "source")
      return `${environment.appUrl}${environment.apiUrlPart}/v1/sourceDiffDocs/finalDocSets/${abstractUid}/docPairDiffs/${diffUid}/documents/${docUid}`;
    return `${environment.appUrl}${environment.apiUrlPart}/v1/targetDiffDocs/finalDocSets/${abstractUid}/docPairDiffs/${diffUid}/documents/${docUid}`;
  }

  saveBookmark(
    abstractUid: string,
    payload
  ): Observable<OApiRespMinimalBookmarkViewModel> {
    const _payload = {
      data: payload,
      returnParams: {
        view: "minimal",
      },
    };
    return this.restClient.sendPostRequest<OApiRespMinimalBookmarkViewModel>(
      AbstractReviewService.API_VERSION_1,
      AbstractReviewService.getBookmarksBaseUrl(abstractUid),
      _payload,
      OApiRespMinimalBookmarkViewModel
    );
  }

  getBookmarksList(
    abstractUid: string
  ): Observable<OApiRespTableOfContentsViewModel> {
    return this.restClient.sendGetRequest<OApiRespTableOfContentsViewModel>(
      AbstractReviewService.API_VERSION_1,
      `/finalDocSets/${abstractUid}/tableOfContents`,
      OApiRespTableOfContentsViewModel,
      {
        params: {
          view: "full",
        },
      }
    );
  }

  removeBookmark(
    abstractUid: string,
    bookmarkUid: string
  ): Observable<OApiRespMinimalBookmarkViewModel> {
    return this.restClient.sendDeleteRequest<OApiRespMinimalBookmarkViewModel>(
      "/v1",
      `/finalDocSets/${abstractUid}/bookmarks/${bookmarkUid}`,
      OApiRespMinimalBookmarkViewModel
    );
  }

  updateBookmark(
    abstractUid: string,
    bookmarkUid: string,
    payload
  ): Observable<OApiRespMinimalBookmarkViewModel> {
    const _payload = {
      data: payload,
      returnParams: {
        view: "none",
      },
      replace: false,
    };
    return this.restClient.sendPutRequest<OApiRespMinimalBookmarkViewModel>(
      AbstractReviewService.API_VERSION_1,
      `/finalDocSets/${abstractUid}/bookmarks/${bookmarkUid}`,
      _payload,
      OApiRespMinimalBookmarkViewModel
    );
  }

  // pickSnippet(abstractUid: string, payload): Observable<any> {
  //     const _payload = {
  //         data: payload,
  //         returnParams: {
  //             view: 'none',
  //         },
  //         replace: false,
  //     }
  //     return this.restClient.sendPostRequestNoView(
  //         AbstractReviewService.API_VERSION_1,
  //         `/finalDocSets/${abstractUid}/pickSnippetAndReplaceMentions`,
  //         _payload,
  //     )
  // }
}
